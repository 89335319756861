
import { IonCard, IonList, IonLabel, IonIcon } from "@ionic/vue";
import { informationCircle } from "ionicons/icons";
import { useRouter } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { openLearnWhereToStartModal } from "@/services/InformationPagesService";
import {Quadrant, getMediaItem, Journey} from "@/storage/DataStorage";
import { useStore } from "vuex";
import translate from "./translate";
import {getImageDataUri} from "@/utilities/Compatibility";

export default {
    name: "Fields",
    components: {
        IonCard,
        IonList,
        IonLabel,
        IonIcon,
    },

    async ionViewWillEnter() {
        const currentView = this as any;
        if (currentView.localeMessages.application) {
            await currentView.$store.dispatch("setHeaderImage", getImageDataUri(currentView.localeMessages.application.homeScreenIcon));
            await currentView.$store.dispatch("setHeaderTitle", `${currentView.localeMessages.application.appName}`);
        }
        if (!currentView.isInitialLoad) {
            currentView.recentJourneys = await currentView.$store.getters.getRecentJourneys(currentView.$store.state);
        }
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const recentJourneys = ref([] as Journey[]);
        const quadrants = ref([] as Quadrant[]);
        const images = ref([6] as any);
        const isInitialLoad = computed(() => store.getters.isFirstTimeOpeningApp());
        const showQuadrants = ref(false);

        onMounted(async () => {            
            if (isInitialLoad.value) {
                const quadrantsToShow = store.getters.quadrantsToShow();
                quadrants.value = store.getters.getMinRequiredLoadData().quadrants.filter((quadrant: Quadrant) => quadrantsToShow.includes(quadrant.id));
            } else {
                recentJourneys.value = await store.getters.getRecentJourneys();
                quadrants.value = await store.getters.getQuadrants("fields");
            }
            
            await Promise.all(
                quadrants.value.map(async (quadrant: Quadrant) => {
                    const useAlternateQuadrantInfo = (quadrant.id === 'jf5' || quadrant.id === 'jf6') && store.getters.showAllQuadrants();
                    const index = parseInt(quadrant.id.replace('jf', ''), 10) - 1;
                    if (isInitialLoad.value) {
                        images.value[index] = getImageDataUri(useAlternateQuadrantInfo ? quadrant.altIcon : quadrant.icon);
                    } else {
                        const { content } = await getMediaItem(quadrant.iconId);
                        images.value[index] = getImageDataUri(content);
                    }
                    
                    if (useAlternateQuadrantInfo) {
                        quadrant.name = quadrant.altName || quadrant.name;
                    }
                })
            );

            showQuadrants.value = true;
        });

        const router = useRouter();
        const routeToJourneySet = (fieldId: string) => {
            router.push({ name: "journey-sets", params: { fieldId: fieldId } });
        };
        const routeToJourney = async (journey: Journey) => {
            const journeySet = await store.getters.getJourneySet(journey.journeySetId);
            const journeyFieldId = journeySet.quadrantId;
            await router.push({ name: "journey", params: { fieldId: journeyFieldId, journeySetId: journey.journeySetId, journeyId: journey.id } });
        };

        const getQuadrantClasses = (index: number) => {
            return ["quadrant", `field-${++index}`];
        };
        
        return {
            quadrants,
            recentJourneys,
            routeToJourneySet,
            informationCircle,
            getQuadrantClasses,
            images,
            store,
            t,
            openLearnWhereToStartModal,
            localeMessages: computed(() => store.getters.getLocaleMessages()),
            routeToJourney,
            isInitialLoad,
            showQuadrants
        };
    },
};
