import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51e0e78a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = { class: "quadrant-cycle" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "recent-journeys-header" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "recent-journey-content__words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { shouldShowSubHeader: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.quadrants, (quadrant, index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_ion_card, {
              key: quadrant.id,
              class: _normalizeClass($setup.getQuadrantClasses(index)),
              onClick: ($event: any) => ($setup.routeToJourneySet(quadrant.id))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_3, _toDisplayString(quadrant.name), 1),
                _createVNode(_component_ion_icon, {
                  src: $setup.images[index],
                  alt: quadrant.name
                }, null, 8, ["src", "alt"])
              ]),
              _: 2
            }, 1032, ["class", "onClick"])), [
              [_vShow, $setup.showQuadrants]
            ])
          }), 128))
        ]),
        _createElementVNode("p", {
          class: "learn-where",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openLearnWhereToStartModal($setup.localeMessages)))
        }, [
          _createElementVNode("span", null, _toDisplayString($setup.t("messages.application.learnWhereToStart")), 1)
        ]),
        ($setup.recentJourneys.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_list, {
              key: 0,
              class: "recent-journeys"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.t("messages.application.recentJourneys")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recentJourneys.slice(), (journey, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "recent-journey-content app-background",
                    key: index,
                    onClick: ($event: any) => ($setup.routeToJourney(journey))
                  }, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_ion_icon, {
                        class: "recent-journey-content__icon",
                        icon: journey.icon
                      }, null, 8, ["icon"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_ion_label, { class: "journey-title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(journey.name), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_label, { class: "journey-sub-title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(journey.description), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ], 8, _hoisted_5))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}